import { FC, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useIdleTimer } from 'react-idle-timer'
import { BrowserRouter as Router, useLocation, useParams } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { useDetectAdBlock } from 'adblock-detect-react'

import { BrowserNotSupportedMessage } from '@/components/browser-not-supported-message'
import { IdleWarningModal } from '@/components/idle-warning-modal'
import { Layout } from '@/components/layout'
import { Spinner } from '@/components/spinner/spinner'
import { AppRoute } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { setAppIdleState } from '@/store/slices/app.slice'

import { AuthController } from './libs/components/auth-controller'
import { DataWatcher } from './libs/components/data-watcher'
import { NavigationWatcher } from './libs/components/navigation-watcher'

declare global {
  interface Window {
    twq: {
      (...args: any[]): void
      exe?: (...args: any[]) => void
      version: string
      queue: IArguments[]
    }
  }
}

const App: FC = () => {
  const isAppIdle = useAppSelector((state) => state.app.isAppIdle)
  const isBrowserSupported = useAppSelector((state) => state.app.isBrowserSupported)
  const browserInfo = useAppSelector((state) => state.app.browserInfo)

  const [isAppLoaded, setIsAppLoaded] = useState(false)

  const adBlockDetected = useDetectAdBlock()
  console.log('adBlockDetected', adBlockDetected)

  const dispatch = useAppDispatch()

  useEffect(() => {
    ;(() => {
      const bait =
        'https://googleads.g.doubleclick.net/pagead/gen_204?id=wfocus&gqid=advertisment&advert=ads'
      console.log('Throw a bait')

      fetch(bait)
        .then((res) => {
          console.log('bait res', res)
        })
        .catch((err) => {
          console.log('bait err', err)
        })
    })()
  }, [])

  useIdleTimer({
    onIdle: () => {
      dispatch(setAppIdleState(true))
    },
    onAction: () => {
      return
    },
    // Idle timeot in ms
    timeout: 1800_000,
    throttle: 500,
  })

  return (
    <Router>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <CssBaseline />
          {isBrowserSupported && (
            <>
              <DataWatcher />
              {isAppIdle && <IdleWarningModal />}
              {isAppLoaded && <Layout />}
              {!isAppLoaded && <Spinner absoluteCentered />}
              <PageTitle />
              <ToastContainer theme="dark" />
              <AuthController setIsAppLoaded={setIsAppLoaded} />
              <NavigationWatcher />
            </>
          )}
          {!isBrowserSupported && <BrowserNotSupportedMessage />}
        </StyledEngineProvider>
      </HelmetProvider>
    </Router>
  )
}

const isProduction = process.env.NODE_ENV === 'production'
function PageTitle() {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const { mode } = useParams()
  const location = useLocation()

  useEffect(() => {
    if (isProduction && window.twq) {
      if (localStorage.signUpData && location.pathname.includes(AppRoute.CREATE_MASTER_PASSWORD)) {
        window.twq('event', 'tw-ojn9a-onkhx')
      }
    }
  }, [location.pathname])

  useEffect(() => {
    if (isProduction && window.twq) {
      window.twq('track', 'PageView')
    }
  }, [])

  return (
    <div>
      {currentToken && (
        <Helmet>
          <title>{`Blazing Bot ${currentToken ? ' | ' + currentToken?.token.symbol + ' $' + currentToken?.token.price : ''}`}</title>
          <meta
            property="og:title"
            content={`Blazing Bot ${currentToken ? ' | ' + currentToken?.token.symbol + ' $' + currentToken?.token.price : ''}`}
          />
          <meta
            property="og:url"
            content={`https://dev.app.blazingbot.com/dashboard/${mode}?token=${currentToken?.token.address}`}
          />
          <meta
            property="og:description"
            content={`${currentToken?.token.symbol} on Ethereum | 💰 Price: $${currentToken?.token.price}`}
          />
        </Helmet>
      )}
    </div>
  )
}

export { App, PageTitle }
