import { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MUITabs, { TabsProps as MUITabsProps } from '@mui/material/Tabs'
import cn from 'classnames'

import useCheckUser from '@/hooks/useCheckUser.ts'
import { DEFAULT_TOKEN } from '@/libs/configs/default-token.config.ts'
import { TabName } from '@/libs/enums'
import { TTab } from '@/libs/types'
import { useAppSelector } from '@/store'

import { Tab } from './libs/components/tab'
import { TabContent } from './libs/components/tab-content'
import { findActiveTabIndex } from './libs/helpers/find-active-tab-index'
import styles from './styles.module.scss'

type TTabsProps = MUITabsProps & {
  name?: string
  tabs: TTab[]
  tabControl?: boolean
  buttonsMargin?: boolean
  tabsClasses?: string
  tabContentClasses?: string
  variant?: 'fullWidth' | 'standard' | 'scrollable'
  isMain?: boolean
  handleChangeTab?: (newValue: number) => void
}

const Tabs: FC<TTabsProps> = ({
  name = '',
  tabs,
  variant = 'fullWidth',
  tabsClasses,
  tabContentClasses,
  tabControl,
  defaultValue = 0,
  buttonsMargin = true,
  className,
  value,
  handleChangeTab,
  isMain,
  onChange,
  ...props
}) => {
  const { userCurrentSelectedTemplate } = useAppSelector((state) => state.user)
  const [currentValue, setCurrentValue] = useState<number | null>(Number(defaultValue))
  const [currentTab, setCurrentTab] = useState<TTab | null>(null)
  const quickBuySettings = useAppSelector((state) => state.user.quickBuySettings)
  const [searchParams, setSearchParams] = useSearchParams()
  const activeTab = searchParams.get(name)
  const settingNav = searchParams.get(TabName.SETTING_NAV)

  const handleDisabledTab = (index: number | null) => {
    const currentTab = index !== null ? tabs[index] : null
    if (currentValue === null || currentTab?.isDisabled) {
      const firstActiveTabIndex = tabs.findIndex((tab) => !tab.isDisabled)
      if (firstActiveTabIndex >= 0) {
        index = firstActiveTabIndex
      } else {
        setCurrentValue(null)
        setCurrentTab(null)
        return
      }
    }
    setCurrentValue(index)
    setCurrentTab(currentTab)
    if (index !== null && tabs[index].label.toLowerCase() !== currentTab?.label.toLowerCase()) {
      searchParams.set(name, tabs[index].label.toLowerCase())
      setSearchParams(searchParams)
    }
  }

  useEffect(() => {
    handleDisabledTab(defaultValue as number)
  }, [])

  useEffect(() => {
    if (quickBuySettings.isInitialized && name === TabName.DASHBOARD_NAV) {
      handleDisabledTab(0)
    } else {
      handleDisabledTab(currentValue)
    }
  }, [tabs, quickBuySettings.isInitialized])

  useEffect(() => {
    if (value || value === 0) {
      setCurrentValue(value)
    }
  }, [value, userCurrentSelectedTemplate])
  const checkUserAndExecute = useCheckUser()

  useEffect(() => {
    if (activeTab) {
      searchParams.delete(name)
      setSearchParams(searchParams)

      const index = findActiveTabIndex(tabs, activeTab)
      if (tabs[index] && !tabs[index].isDisabled) {
        handleDisabledTab(index)
      }

      if (settingNav && index !== null) {
        checkUserAndExecute(() => {
          setSearchParams({ [TabName.DASHBOARD_NAV]: activeTab, [TabName.SETTING_NAV]: settingNav })
        })
      }
    }
  }, [activeTab, settingNav, name, tabs])

  const handleChange = (e: React.SyntheticEvent, newValue: number) => {
    checkUserAndExecute(() => {
      setCurrentValue(newValue)

      const paramsToSet: { [key: string]: string } = {
        [TabName.DASHBOARD_NAV]: tabs[newValue].label.toLowerCase(),
      }

      if (settingNav) {
        paramsToSet[TabName.SETTING_NAV] = settingNav
      }

      if (isMain) {
        setSearchParams(paramsToSet)
      }

      if (handleChangeTab) {
        handleChangeTab(newValue)
      }

      if (onChange) {
        onChange(e, newValue)
      }
    })
  }

  const getTabs = () => {
    return tabs.map(({ label, isDisabled, icon }, index) => (
      <Tab
        label={label}
        key={label}
        disabled={tabControl ? index > value : isDisabled}
        icon={icon}
        className={styles.tab}
      />
    ))
  }

  const getTabsContent = () => {
    return tabs.map(({ content }, index) => (
      <TabContent
        value={currentValue as any}
        index={index}
        key={index}
        className={tabContentClasses}
      >
        {content}
      </TabContent>
    ))
  }

  return (
    <Grid className={cn(styles.tabContainer, className)}>
      <Box>
        <MUITabs
          className={cn(styles.tabsButtonPanel, tabsClasses, {
            [styles.buttonsMargin]: buttonsMargin,
            [styles.standard]: variant === 'standard',
          })}
          sx={{
            '.MuiTabs-scroller': { maxWidth: variant === 'standard' ? 'fit-content' : 'none' },
          }}
          classes={styles}
          value={currentValue}
          onChange={handleChange}
          TabIndicatorProps={{ className: styles.indicator }}
          variant={variant}
          {...props}
        >
          {getTabs()}
        </MUITabs>
      </Box>
      {(currentValue || currentValue === 0) &&
        currentTab &&
        !currentTab.isDisabled &&
        getTabsContent()}
    </Grid>
  )
}

export { Tabs, type TTabsProps }
