import { FC } from 'react'

import { Grid } from '@mui/material'

import { ButtonLink, Icon, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { ESidebarBtnType } from '@/libs/enums/sidebar-btn-type'
import { convertScientificNotationNumber } from '@/libs/helper/convertScientificNotationNumber'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice'
import { hideWalletAddress } from '@/libs/helper/hideWalletAddress'
import { TUserWallet } from '@/libs/types/user.type'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = {
  wallet: TUserWallet
  type?: ESidebarBtnType
}

const WalletItem: FC<TProps> = ({ wallet, type }) => {
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const getLinkBaseOnType = () => {
    switch (type) {
      case ESidebarBtnType.TRANSFER:
      case ESidebarBtnType.DISPERSE_COLLECT:
        return `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.WALLET_DETAILS}/${wallet.address}?type=${type}`
      case ESidebarBtnType.DEPOSIT:
        return `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.DEPOSIT_TO_WALLET}/${wallet.address}`
      default:
        return `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.WALLET_DETAILS}/${wallet.address}?type=${ESidebarBtnType.TRANSFER}`
    }
  }

  return (
    <ButtonLink to={getLinkBaseOnType()} className={styles.element}>
      <div className={styles.info}>
        <div>
          <Typography variant="body2" className={styles.title}>
            {wallet.name}
          </Typography>
          <Typography variant="body2">{hideWalletAddress(wallet.public_key)}</Typography>
        </div>
      </div>
      <div className={styles.controls}>
        <Grid justifyContent="flex-end">
          <Typography variant="body2" align="right" textColor="color-grey-3">
            {formatTokenPrice(wallet.balanceFormatted || '0').formatted} {currentChain.description}
          </Typography>
          <Typography variant="body2" align="right">
            $
            {convertScientificNotationNumber(
              +(wallet.balanceFormatted || 0) * (currentChain.nativeTokenPriceInUsd || 0),
              2,
            )}
          </Typography>
        </Grid>
        <div className={styles.link}>
          <Icon name={IconName.CHEVRON_RIGHT} />
        </div>
      </div>
    </ButtonLink>
  )
}

export { WalletItem }
