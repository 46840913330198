import { Dispatch, FC, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'

import cn from 'classnames'

import useCheckUser from '@/hooks/useCheckUser'
import { useWindowDimensions } from '@/hooks/useWindowDimensions'
import { Button, ButtonIcon, Icon, Typography } from '@/libs/common'
import { AppMode, AppRoute, IconName } from '@/libs/enums'
import { ESidebarBtnType } from '@/libs/enums/sidebar-btn-type'

import { CollapsedIcon } from './libs/components/collapsed-icon'
import { FavoritesAccordion } from './libs/components/favorites-accordion'
import { HoldingsAccordion } from './libs/components/holdings-accordion'
import { TemplatesAccordion } from './libs/components/templates-accordion'
import { WalletAccordion } from './libs/components/wallet-accordion'
import styles from './styles.module.scss'

type Props = {
  collapsed: boolean
  setCollapsed: Dispatch<SetStateAction<boolean>>
}

const SidebarMinimised: FC<Props> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate()
  const checkUserAndExecute = useCheckUser()
  const windowDimension = useWindowDimensions()
  const isResponsive = windowDimension.width <= 1199

  const toggleSidebar = () => {
    setCollapsed((prev) => !prev)
  }

  return (
    <>
      <div
        className={cn(styles.wrapper, {
          [styles.collapsed]: collapsed,
        })}
      >
        {!isResponsive && (
          <div className={styles.arrowContainer} onClick={toggleSidebar}>
            <Icon name={IconName.DOUBLE_ARROW_LEFT} />
          </div>
        )}

        {isResponsive && (
          <div className={styles.userHub}>
            <Typography variant="body1" textColor="white">
              User hub
            </Typography>

            <ButtonIcon icon={IconName.CLOSE} onClick={toggleSidebar} />
          </div>
        )}

        <div>
          <div className={styles.btnContainer}>
            <Button
              styleVariant="black"
              className={styles.button}
              onClick={() =>
                checkUserAndExecute(() => {
                  navigate(
                    `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.SELECT_WALLET_TO_DEPOSIT}`,
                  )
                })
              }
            >
              <Icon name={IconName.DEPOSIT} /> <span className={styles.btnLabel}>Deposit</span>
            </Button>
            <Button
              styleVariant="black"
              className={styles.button}
              onClick={() =>
                checkUserAndExecute(() => {
                  navigate(
                    `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.TRANSFER_WALLETS}?type=${ESidebarBtnType.TRANSFER}`,
                  )
                })
              }
            >
              <Icon name={IconName.WITHDRAW} />
              <span className={styles.btnLabel}>Transfer</span>
            </Button>
            <Button
              styleVariant="black"
              className={styles.button}
              onClick={() =>
                checkUserAndExecute(() => {
                  navigate(
                    `${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.TRANSFER_WALLETS}?type=${ESidebarBtnType.DISPERSE_COLLECT}`,
                  )
                })
              }
            >
              <Icon name={IconName.CROSS} />
              <span className={styles.btnLabel}>Disperse/Collect</span>
            </Button>
          </div>

          <div className={styles.accordionsWrapper}>
            <div className={styles.accordionsContainer}>
              <WalletAccordion isOpen={!collapsed} />
              <TemplatesAccordion isOpen={!collapsed} />
              <FavoritesAccordion isOpen={!collapsed} />
              <HoldingsAccordion isOpen={!collapsed} />
            </div>
            <div className={styles.accordionIcons}>
              <CollapsedIcon
                iconName={IconName.WALLET_GREY}
                label="Wallets"
                redirectTo={`${AppRoute.MODAL}/${AppRoute.WALLET_MANAGEMENT}`}
              />
              <CollapsedIcon
                iconName={IconName.TEMPLATES_GREY}
                label="Templates"
                redirectTo={`${AppRoute.DASHBOARD}/${AppMode.PRO}/${AppRoute.MODAL}/${AppRoute.TEMPLATE_MANAGEMENT}`}
              />
              <CollapsedIcon
                iconName={IconName.STAR_GREY_BOLD}
                label="Favorites / History"
                redirectTo={`${AppRoute.MODAL}/${AppRoute.FAVORITES_HISTORY}`}
              />
              <CollapsedIcon
                iconName={IconName.HOLDINGS_GREY}
                label="Holdings"
                redirectTo={`${AppRoute.MODAL}/${AppRoute.HOLDINGS}`}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className={cn(styles.overlay, { [styles.sidebarOpen]: !collapsed })}
        onClick={(e) => {
          e.stopPropagation()
          setCollapsed(true)
        }}
      />
    </>
  )
}

export { SidebarMinimised }
