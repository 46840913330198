import { useCallback } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { AppMode, AppRoute, TabName } from '@/libs/enums'
import { createQueryString } from '@/libs/helper/createQueryString'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchNewCurrentToken, setSelectedTokenAddress } from '@/store/slices/chain.slice'

type TFuncProps = {
  skipTokenFetch?: boolean
  skipRedirect?: boolean
  skipTokenSetToUrl?: boolean
  replaceNavigation?: boolean
  customAppMode?: string | null
  isForceNavigate?: boolean
  customQueryParams?: Record<string, any>
  customRedirectPath?: string | null
}

let isTokenDataLoading = false

const useSelectNewToken = () => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { mode } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const settingNav = searchParams.get(TabName.SETTING_NAV)

  return useCallback(
    async (tokenAddress: string, props?: TFuncProps) => {
      const { isForceNavigate } = props || {}
      if (
        isTokenDataLoading ||
        (currentToken &&
          currentToken.token.address.toLowerCase() === tokenAddress.toLowerCase() &&
          !isForceNavigate)
      ) {
        return
      }

      isTokenDataLoading = true

      const {
        skipTokenFetch,
        skipRedirect,
        skipTokenSetToUrl,
        replaceNavigation,
        customAppMode,
        customQueryParams = {},
        customRedirectPath,
      } = props || {}

      dispatch(setSelectedTokenAddress(tokenAddress))
      const activeDashTab = searchParams.get(TabName.DASHBOARD_NAV)

      if (skipRedirect && !skipTokenSetToUrl) {
        searchParams.set('token', tokenAddress)
        searchParams.set(TabName.DASHBOARD_NAV, activeDashTab ?? 'dashboard')
        searchParams.set(TabName.SETTING_NAV, settingNav ?? '')
        setSearchParams(searchParams)
      } else if (!skipRedirect) {
        navigate(
          {
            pathname:
              customRedirectPath || `${AppRoute.DASHBOARD}/${customAppMode || mode || AppMode.PRO}`,
            search: createQueryString({
              token: tokenAddress,
              [TabName.DASHBOARD_NAV]: 'dashboard',
              ...customQueryParams,
            }),
          },
          { replace: replaceNavigation },
        )
      }

      if (!skipTokenFetch) {
        await dispatch(fetchNewCurrentToken(tokenAddress)).unwrap()
      }

      isTokenDataLoading = false
    },
    [currentToken, navigate, dispatch, mode, searchParams],
  )
}

export { useSelectNewToken }
